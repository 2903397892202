import request from '@/utils/request'

import qs from 'qs'

const api_name = '/admin/erp/ordersInTransit'

export default {
  // 查看俩天后到货的在途订单
  selectPageInTwoDays(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInTwoDays/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // 查看三天后到货的在途订单
  selectPageInThreeDays(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInThreeDays/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },

  // 查看已超时的在途订单
  selectPageInExpiry(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInExpiry/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // 查看今日新增的在途订单
  selectPageByToday(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageByToday/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // 查看全部的在途订单
  selectPageAll(page, limit, searchObj) {
    return request({
      url: `${api_name}/selectPageAll/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },

  // 查看详情
  show(id) {
    return request({
      url: `${api_name}/show/` + id,
      method: 'get',
    })
  },

  // 添加备注
  updateData(orderVo) {
    return request({
      url: `${api_name}/updateData`,
      method: 'post',
      data: orderVo,
    })
  },
  // 获取全部数据标签
  selectAllLables() {
    return request({
      url: `/admin/erp/lableInfo/list`,
      method: 'get',
    })
  },

  // 供应商在途订单 全部
  pageInSupplierAll(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInSupplierAll/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // 供应商在途订单 近期
  pageInSupplierVerySoon(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInSupplierVerySoon/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },

  // 供应商在途订单 超期
  pageInSupplierExpired(page, limit, searchObj) {
    return request({
      url: `${api_name}/pageInSupplierExpired/` + page + `/` + limit,
      method: 'get',
      params: searchObj, // url查询字符串或表单键值对
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },

  // 查看 供应商 详情
  supplierShow(
    purchaseOrderType,
    purchaseOrderId,
    contractNumber,
    serialNumber
  ) {
    return request({
      url:
        `${api_name}/supplierShow/` +
        purchaseOrderType +
        `/` +
        purchaseOrderId +
        `/` +
        contractNumber +
        `/` +
        serialNumber,
      method: 'get',
    })
  },

  // 添加备注
  supplierUpdateData(orderVo) {
    return request({
      url: `${api_name}/supplierUpdateData`,
      method: 'post',
      data: orderVo,
    })
  },
  // 供应商在途订单总结
  orderSummary() {
    return request({
      url: `${api_name}/orderSummary`,
      method: 'get',
    })
  },
  // 添加备注
  reminder(reminderVoList) {
    return request({
      url: `${api_name}/reminder`,
      method: 'post',
      data: reminderVoList,
    })
  },
}
