import request from '@/utils/request'

export default {
  bindPhone(bindPhoneVo) {
    return request({
      url: `/admin/wechat/bindPhone`,
      method: 'post',
      data: bindPhoneVo,
    })
  },

  getCurrentUser() {
    return request({
      url: `/admin/process/getCurrentUser/`,
      method: 'get',
    })
  },
  // 获取对应用户的微信菜单
  findRoleMenu() {
    return request({
      url: `/admin/wechat/menu/findRoleMenu`,
      method: 'get',
    })
  },
  // 发送验证码
  sendAutoCordSms(phone) {
    return request({
      url: `/admin/wechat/index/authCode`,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({ phone }).toString(),
    })
  },

  // 获取用户信息
  userInfo() {
    return request({
      url: `/admin/system/sysUser/userInfo`,
      method: 'get',
    })
  },
}
