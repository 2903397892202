import Vue from 'vue'
import VueRouter from 'vue-router'
import userInfoApi from '@/api/userInfo'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'OA审批',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/apply/:processTemplateId',
    name: 'Apply',
    component: () => import('../views/apply.vue'),
  },
  {
    path: '/list/:activeIndex',
    name: '审批列表',
    component: () => import('../views/list.vue'),
  },
  {
    path: '/transitList/:activeIndex',
    name: '在途订单列表',
    component: () => import('../views/ordersInTransitList.vue'),
  },
  {
    path: '/transitShow/:id',
    name: '在途订单详情',
    component: () => import('../views/ordersInTransitShow.vue'),
  },
  {
    path: '/supplierList/:activeIndex',
    name: '供应商订单列表',
    component: () => import('../views/supplierInTransitList.vue'),
  },
  {
    path: '/supplierShow/:purchaseOrderType/:purchaseOrderId/:contractNumber/:serialNumber',
    name: '供应商订单详情',
    component: () => import('../views/supplierInTransitShow.vue'),
  },

  {
    path: '/show/:id/:taskId',
    name: '审批详情',
    component: () => import('../views/show.vue'),
  },
  {
    path: '/user',
    name: '基本信息',
    component: () => import('../views/user.vue'),
  },
  {
    path: '/about',
    name: '关于我们',
    component: () => import('../views/about.vue'),
  },
  {
    path: '/test',
    name: '测试',
    component: () => import('../views/test.vue'),
  },
]

const router = new VueRouter({
  routes,
})

import store from '@/store'

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const wxUrls = window.localStorage.getItem('wxUrls')

  console.log('路由判断路径:' + to.path)

  if (wxUrls) {
    const allowedUrls = JSON.parse(wxUrls) // 将存储的 JSON 字符串解析为数组
    // 是否包含根路径 `/`
    const hasRootPath = allowedUrls.includes('/')

    // 检查是否授权
    const isAuthorized = allowedUrls.some((url) => {
      if (hasRootPath && url === '/') {
        // 如果包含 `/`，则严格匹配根路径
        return to.path === '/'
      }
      // 非根路径匹配规则
      return url !== '/' && to.path.includes(url)
    })

    if (isAuthorized) {
      // 如果用户有访问权限，继续导航
      store.dispatch('setNoPermission', false) // 有权限
      next()
    } else {
      // 如果没有权限，重定向到 /user 页面
      store.dispatch('setNoPermission', true) // 设置无权限状态
      next() // 不跳转，保持当前页面
      // 控制 App.vue 中的 noPermission 为 true，显示无权限提示
    }
  } else {
    // 如果没有这个参数则调用接口获取列表
    userInfoApi
      .findRoleMenu()
      .then((response) => {
        console.log('请求: ' + response)
        const urls = response.data
          .filter((item) => item.url) // 过滤掉没有 `url` 的数据
          .map((item) => item.url) // 提取 `url`
        window.localStorage.setItem('wxUrls', JSON.stringify(urls))
        // 获取数据成功后重新判断当前路由权限
        router.push(to.fullPath) // 重新导航到当前路径，触发路由守卫
      })
      .catch((error) => {
        console.error('异常: ' + error)
        store.dispatch('setNoPermission', true) // 设置无权限状态
        next() // 不跳转，保持当前页面
      })
  }
})

export default router
