// utils/watermark.js
const addWatermark = ({
  containerSelector = 'body', // 默认添加到 body
  text = 'Confidential', // 水印内容
  font = '18px Arial', // 字体样式
  color = 'rgba(0, 0, 0, 0.25)', // 水印颜色
  width = 200, // 水平间隔（间距减小，增加密度）
  height = 150, // 垂直间隔（间距减小，增加密度）
  angle = -30, // 水印旋转角度
} = {}) => {
  // 创建水印容器
  const container = document.querySelector(containerSelector) || document.body

  // 防止重复添加水印
  if (container.querySelector('.watermark')) {
    console.warn('Watermark already exists')
    return
  }

  const watermarkDiv = document.createElement('div')
  watermarkDiv.className = 'watermark'
  watermarkDiv.style.position = 'absolute'
  watermarkDiv.style.top = '0'
  watermarkDiv.style.left = '0'
  watermarkDiv.style.width = '100%'
  watermarkDiv.style.height = '100%'
  watermarkDiv.style.pointerEvents = 'none' // 确保水印不可点击
  watermarkDiv.style.overflow = 'hidden'
  watermarkDiv.style.zIndex = '9999'

  // 创建水印画布
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  ctx.font = font
  ctx.fillStyle = color
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.translate(width / 2, height / 2) // 平移到中心
  ctx.rotate((angle * Math.PI) / 180) // 旋转角度
  ctx.fillText(text, 0, 0)

  // 将画布内容设置为背景
  const base64Url = canvas.toDataURL()
  watermarkDiv.style.backgroundImage = `url(${base64Url})`
  watermarkDiv.style.backgroundRepeat = 'repeat'

  // 将水印添加到页面中
  container.style.position = 'relative'
  container.appendChild(watermarkDiv)

  // 防止水印被删除
  const observer = new MutationObserver(() => {
    if (!container.querySelector('.watermark')) {
      container.appendChild(watermarkDiv)
    }
  })
  observer.observe(container, { childList: true, subtree: true })
}

export default addWatermark
