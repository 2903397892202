<template>
  <div>
    <van-nav-bar
      title="发起审批"
      left-text="返回"
      left-arrow
      @click-left="() => $router.back()"
    />
    <div style="margin: 10px 10px 0 0; border: 0px solid red">
      <form-create
        :rule="mergedRule"
        :option="option"
        @submit="onSubmit"
      ></form-create>
    </div>
  </div>
</template>

<script>
import api from '@/api/process'
import { MessageBox } from 'element-ui'

export default {
  name: 'process',

  data() {
    return {
      processTemplate: {},
      // 本身自带的表单
      rule: [],
      // 全局配置
      option: {},
      // 人员 表单
      rankRule: [],
      departmentList: [], // 部门数据
      personnelList: [], // 主送人员数据
      notifiedPersonList: [], // 抄送人员列表
      fileIdList: [], // 文件id数据
    }
  },

  created() {
    let processTemplateId = this.$route.params.processTemplateId
    this.fetchData(processTemplateId)
    // 获取部门数据
    this.fetchDepartmentData()
  },
  computed: {
    mergedRule() {
      return [...this.rule, ...this.rankRule]
    },
  },

  methods: {
    async fetchData(processTemplateId) {
      const response = await api.getProcessTemplate(processTemplateId)

      this.processTemplate = response.data
      this.rule = JSON.parse(this.processTemplate.formProps)
      this.option = JSON.parse(this.processTemplate.formOptions)

      // 等待文件上传的组件
      await this.addFileUploadField()

      if (this.processTemplate.templateType === 1) {
        await this.addDepartmentAndPersonnelFields()
      }
    },

    // 动态添加部门和人员选择字段
    async addDepartmentAndPersonnelFields() {
      await this.fetchDepartmentData()

      // 主送部门
      const departmentField = {
        type: 'select',
        field: 'department',
        title: '主送部门',
        options: this.departmentList, // 直接赋值
        props: {
          placeholder: '请选择主送部门',
          multiple: true,
        },
        validate: [{ required: true, message: '请选择主送部门' }],
        on: {
          change: this.onDepartmentChange,
        },
      }

      // 主送人员
      const personnelField = {
        type: 'select',
        field: 'personnel',
        title: '主送人员',
        options: [], // 初始为空
        props: {
          placeholder: '请选择主送人员',
          multiple: true,
        },
        validate: [{ required: true, message: '请选择主送人员' }],
      }
      // 抄送部门
      const notifiedField = {
        type: 'select',
        field: 'notifiedDepartment',
        title: '抄送部门',
        options: this.departmentList, // 直接赋值
        props: {
          placeholder: '请选择抄送部门',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送部门' }],
        on: {
          change: this.onNotifiedChange,
        },
      }

      // 抄送人员
      const notifiedPersonslField = {
        type: 'select',
        field: 'notifiedPerson',
        title: '抄送人员',
        options: [], // 初始为空
        props: {
          placeholder: '请选择抄送人员',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送人员' }],
      }

      // 替换整个 rule 数组，触发重新渲染
      this.rankRule = [
        ...this.rankRule,
        departmentField,
        personnelField,
        notifiedField,
        notifiedPersonslField,
      ]

      // 确保视图更新
      this.$forceUpdate()
    },

    // 添加 文件上传的组件
    async addFileUploadField() {
      let token = window.localStorage.getItem('token') || ''

      const fileUploadField = {
        type: 'upload',
        field: 'file', // 字段名
        title: '附件上传',
        props: {
          multiple: false, // 是否支持多文件上传
          maxSize: 30 * 1024 * 1024, // 最大文件大小 (30MB)
          previewImage: true, // 是否预览
          beforeRemove: (file, fileList) => {
            // 获取当前文件在 fileList 中的下标
            let index = fileList.findIndex((f) => f.uid === file.uid) // 假设你用 uid 作为标识符

            // 获取对应的文件名
            let fileName = file.name

            return new Promise((resolve, reject) => {
              MessageBox.confirm(
                `确定要删除文件 "${fileName}" 吗？`,
                '确认删除',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
              )
                .then(() => {
                  api.deleteFileById(this.fileIdList[index])
                  this.fileIdList.splice(index, 1) // 删除对应的文件ID
                  resolve() // 返回 true，表示删除文件
                })
                .catch(() => {
                  // 用户点击取消，不删
                  reject() // 返回 false，表示取消删除
                })
            })
          },

          name: 'file', // 后端接收的字段名

          accept: '.pdf,.doc,.docx,.xlsx,.txt,.png,.jpg,.ppt,.pptx',

          action: '/prod-api/admin/upload/img', // 上传接口
          onSuccess: (response, file, fileList) => {
            if (response.code === 200) {
              // 提取返回的数据
              const fileId = response.data.id
              // 将文件ID和文件名添加到对应的列表中
              this.fileIdList.push(fileId)
              this.$message.success(`文件上传成功`)
            } else {
              this.$message.error('文件上传失败，请重试！')
            }
          },
          headers: {
            Token: `${token}`, // 如果需要认证，传递 Token
          },
        },
        validate: [{ required: false, message: '请上传文件' }],
      }

      const fileUploadDescription = {
        type: 'vanNoticeBar', // 定义为文本组件
        props: {
          text: '上传类型：ppt、work文档、excel、图片，上传大小限制30MB',
        },
      }

      // 将上传字段和说明文本一起加入 rankRule
      this.rankRule.push(fileUploadDescription)

      this.rankRule.push(fileUploadField)
    },

    // 获取部门数据
    async fetchDepartmentData() {
      try {
        const response = await api.getDepartmentList()
        this.departmentList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        console.log('部门列表:', this.departmentList) // 打印检查数据格式
      } catch (error) {
        console.error('部门数据加载失败:', error)
      }
    },

    // 根据选中的主送部门获取对应的人员数据
    onDepartmentChange(selectedDepartments) {
      if (selectedDepartments.length > 0) {
        api.selectedDepartments(selectedDepartments).then((response) => {
          this.personnelList = response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))

          // 更新人员选择框的 options
          const personnelField = this.rankRule.find(
            (item) => item.field === 'personnel'
          )
          if (personnelField) {
            personnelField.options = this.personnelList
          }
        })
      }
    },

    // 根据选中的抄送部门获取对应的人员数据
    onNotifiedChange(selectedDepartments) {
      if (selectedDepartments.length > 0) {
        api.selectedDepartments(selectedDepartments).then((response) => {
          this.notifiedPersonList = response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))

          // 更新人员选择框的 options
          const personnelField = this.rankRule.find(
            (item) => item.field === 'notifiedPerson'
          )
          if (personnelField) {
            personnelField.options = this.notifiedPersonList
          }
        })
      }
    },
    // 提交
    onSubmit(formData) {
      console.log('formData:', formData)
      console.log('rule', this.rule)
      console.log('rankRule', this.rankRule)
      // 显示数据
      let formShowData = {}
      // 真实数据
      let newFormData = {}

      // 选中的人员
      let selectedPersonnel = []

      // 添加显示数据
      this.rule.forEach((item) => {
        for (let key in formData) {
          if (key === item.field) {
            formShowData[item.title] = formData[key]
            newFormData[item.field] = formData[key]
          }
        }
      })

      console.log('newFormData:', newFormData)

      // 主送人员
      this.rankRule.forEach((item) => {
        for (let key in formData) {
          if (key === 'personnel') {
            formData[key].forEach((id) => {
              if (!selectedPersonnel.includes(id)) {
                selectedPersonnel.push(id) // 直接添加 value
              }
            })
          }
        }
      })

      // 进行抄送
      let selectedNotified = []
      this.rankRule.forEach((item) => {
        for (let key in formData) {
          if (key === 'notifiedPerson') {
            formData[key].forEach((id) => {
              if (!selectedNotified.includes(id)) {
                selectedNotified.push(id) // 直接添加 value
              }
            })
          }
        }
      })

      let DATA = {
        formData: newFormData,
        formShowData: formShowData,
      }
      console.log('data:', DATA)

      let processFormVo = {
        processTemplateId: this.processTemplate.id,
        processTypeId: this.processTemplate.processTypeId,
        formValues: JSON.stringify(DATA),
        approvalNameList: selectedPersonnel,
        templateType: this.processTemplate.templateType,
        fileIds: this.fileIdList,
        notifiedPersonList: selectedNotified,
      }

      console.log('processFormVo', processFormVo)
      api.startUp(processFormVo).then((response) => {
        //调整到已发起列表
        this.$router.push({ path: '/list/2' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  .el-form-item {
    /deep/ .el-form-item__label {
      font-size: 18px;
      font-weight: 800;
      color: blue;
    }
  }
}
</style>
