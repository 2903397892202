<template>
  <div class="container watermark-container">
    <van-nav-bar title="ERP在途订单列表" />
    <van-tabs v-model="activeIndex" @click="tabSwitch">
      <van-tab v-for="(item, key) in tabList" :key="key" :title="item.title">
      </van-tab>
    </van-tabs>

    <!--查询表单-->
    <div class="search-div">
      <el-form label-width="50px" size="small">
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:"
              ><el-input
                style="width: 70%"
                v-model="searchObj.remark"
                placeholder="备注"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 多选下拉框 -->
          <el-col :span="24">
            <el-form-item label="标签:">
              <el-select
                v-model="searchObj.lableId"
                multiple
                style="width: 70%"
                placeholder="请选择标签"
              >
                <!-- 下拉框选项 -->
                <el-option
                  v-for="label in lablsList"
                  :key="label.id"
                  :label="label.name"
                  :value="label.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 合同单号  -->
          <el-col :span="24">
            <el-form-item
              label="合同单号:"
              label-width="auto"
              style="white-space: nowrap"
              ><el-input
                style="width: 60%"
                v-model="searchObj.contractNumber"
                placeholder="如有多个以,隔开"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 供应商名称  -->
          <el-col :span="24">
            <el-form-item
              label="供应商:"
              label-width="auto"
              style="white-space: nowrap"
              ><el-input
                style="width: 60%"
                v-model="searchObj.vendorName"
                placeholder="如有多个以,隔开"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- 时间类型选择 -->
          <el-col :span="24" v-if="activeIndex === 4">
            <el-form-item label="时间类型">
              <el-select
                v-model="searchObj.timeType"
                placeholder="请选择时间类型"
                style="width: 70%"
                :default-value="'1'"
              >
                <el-option label="预期到货时间" value="1"></el-option>
                <el-option label="创建时间" value="2"></el-option>
              </el-select>
            </el-form-item>

            <!-- 日期范围选择 -->
            <el-form-item label="时间范围">
              <el-row>
                <!-- 开始日期 -->
                <el-col :span="11">
                  <el-date-picker
                    v-model="searchObj.startTime"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始日期"
                    style="width: 100%"
                  />
                </el-col>

                <!-- 结束日期 -->
                <el-col :span="2" style="text-align: center; line-height: 36px">
                  ~
                </el-col>

                <el-col :span="11">
                  <el-date-picker
                    v-model="searchObj.endTime"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束日期"
                    style="width: 100%"
                  />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <!-- 搜索按钮 -->
          <el-col :span="6">
            <el-button
              type="primary"
              icon="el-icon-search"
              size="large"
              :loading="loading"
              @click="search"
              style="width: 100%"
            >
              搜索
            </el-button>
          </el-col>

          <!-- 重置按钮 -->
          <el-col :span="6">
            <el-button
              icon="el-icon-refresh"
              size="large"
              @click="resetData"
              style="width: 100%"
            >
              重置
            </el-button>
          </el-col>

          <!-- 全选按钮 -->
          <el-col :span="6">
            <van-checkbox
              v-model="selectAll"
              @change="handleSelectAll"
              style="font-size: 16px; width: 100%"
            >
              全选
            </van-checkbox>
          </el-col>

          <!-- 催单按钮 -->
          <el-col :span="6">
            <button
              @click="reminder"
              :disabled="isDisabledReminder"
              style="
                width: 100%;
                font-size: 16px;
                padding: 12px 24px;
                background-color: #409eff;
                color: white;
                border: none;
                border-radius: 4px;
              "
            >
              催单
            </button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list-wrap">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <van-cell
            v-for="item in list"
            @click="handleCellClick($event, item.id, item.page)"
          >
            <template slot="default">
              <div class="item-wrap">
                <div class="item-header">
                  <!-- 复选框 -->
                  <van-checkbox
                    v-model="selectedItems[item.id]"
                    :name="item.id"
                    :label="{
                      purchaseOrderType: item.purchaseOrderType,
                      purchaseOrderId: item.purchaseOrderId,
                      contractNumber: item.contractNumber,
                      serialNumber: item.serialNumber,
                    }"
                    style="margin-right: 10px"
                    @click.stop.prevent
                  />

                  <img
                    src="https://static.dingtalk.com/media/lALOnahFD80CgM0CgA_640_640.png_450x10000q90.jpg"
                    alt=""
                  />
                  <h3
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span> 产品品号：{{ item.productId }}</span>
                    <span
                      v-if="item.daysExpired && item.daysExpired > 0"
                      style="color: red"
                    >
                      已逾期{{ item.daysExpired }}天
                    </span>
                  </h3>
                </div>

                <div class="item-block">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p>
                      <span>产品品名：{{ item.productName }}</span>
                    </p>
                    <span>
                      <span
                        v-if="item.reminderStatuses === 1"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #f39c12;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        已催单
                      </span>
                      <span
                        v-else-if="item.reminderStatuses === 4"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #27ae60;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        已确认
                      </span>
                      <span
                        v-else-if="item.reminderStatuses === -10"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #3498db;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        待确认
                      </span>
                    </span>
                  </div>

                  <p>
                    <b>合同号：{{ item.contractNumber }}</b>
                  </p>
                  <p>供应商名称：{{ item.vendorName }}</p>
                  <p>应交数量：{{ item.quantityPurchased }}</p>
                  <p>已交数量：{{ item.quantityDelivered }}</p>
                  <p>预到货日：{{ item.expectedArrivalTime }}</p>
                  <p>供应商反馈：{{ item.supplierRemark }}</p>
                  <p v-if="item.remark">备注：{{ item.remark }}</p>

                  <div
                    v-if="item.lableNameList && item.lableNameList.length"
                    class="tag-container"
                  >
                    <van-tag
                      v-for="(tag, index) in item.lableNameList"
                      :key="index"
                      type="primary"
                      style="margin-right: 4px"
                    >
                      {{ tag }}
                    </van-tag>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>

    <div>
      <!-- 弹出框 -->
      <el-dialog
        title="在途订单详情"
        :visible.sync="dialogVisible"
        width="80%"
        @close="handleClose"
      >
        <!-- 使用 iframe 来嵌套页面 -->
        <iframe
          v-if="iframeVisible"
          :src="iframeSrc"
          width="100%"
          height="500"
          frameborder="0"
        ></iframe>

        <!-- 弹出框底部的操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/erpVendorIntransitOrders'

import addWatermark from '@/utils/watermark'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'process',

  data() {
    return {
      id: 0, // 详情id
      oldPage: 1, // 详情所在页码
      dialogVisible: false, // 控制弹出框的显示状态
      iframeVisible: true, // 控制 iframe 的显示和销毁

      list: [],
      loading: false,
      loadingData: false,
      finished: false,
      refreshing: false,
      pageNo: 1,
      pageSize: 10,
      pages: 1,
      searchObj: {},
      // 全部标签列表
      lablsList: [],
      activeIndex: 0,
      tabList: [
        { title: '二天后' },
        { title: '三天后' },
        { title: '已到期' },
        { title: '今日新增' },
        { title: '全部' },
      ],

      selectedItems: {}, // 存储已选中的项
      // 全选框的绑定状态
      selectAll: false,
      // 催单禁用
      isDisabledReminder: false,
    }
  },
  computed: {
    iframeSrc() {
      const timestamp = new Date().getTime() // 获取当前时间戳
      // 使用 window.location.origin 生成完整路径
      const page = this.oldPage // 计算当前页码

      return (
        window.location.origin +
        `/#/transitShow/${this.id}?t=${timestamp}&page=${page}`
      )
    },
    ...mapState({
      userInfo: (state) => state.userInfo, // 从 Vuex 中获取 userInfo
    }),
  },
  created() {
    this.activeIndex = parseInt(this.$route.params.activeIndex)
    this.onLoad()
    this.selectLables()
  },

  mounted() {
    window.addEventListener('message', this.handleMessage)

    // 如果 userInfo 没有值，则调用 Vuex action 获取用户信息
    if (!this.userInfo) {
      this.loadUserInfoAndAddWatermark()
    } else {
      // 如果已有 userInfo，直接添加水印
      this.addWatermark()
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },

  methods: {
    // 获取用户信息缓存
    ...mapActions({
      loadUserInfo: 'fetchUserInfo', // 映射 fetchUserInfo 到组件的方法
    }),
    // 异步方法：先加载用户信息，再添加水印
    async loadUserInfoAndAddWatermark() {
      try {
        await this.loadUserInfo() // 等待 Vuex action 完成
        this.addWatermark() // 用户信息加载完成后添加水印
      } catch (error) {
        // console.error('加载用户信息失败:', error)
      }
    },
    addWatermark() {
      // console.log('打印水印数据:' + this.userInfo.name)
      // 调用水印功能
      addWatermark({
        containerSelector: '.watermark-container', // 添加水印的目标容器
        text: '通用锁具 ' + this.userInfo.name, // 自定义水印文本
        font: '16px Arial',
        color: 'rgba(0, 0, 0, 0.25)',
        width: 200,
        height: 150,
        angle: -30,
      })
    },
    // 查看详情 如果是复选框则不触发
    handleCellClick(event, id, page) {
      // 如果点击的是复选框，阻止继续执行查看详情
      if (event.target.closest('.van-checkbox')) {
        return // 直接返回，不做任何处理
      }

      // 否则，执行查看详情的方法
      this.info(id, page)
    },

    // 全选或取消全选
    handleSelectAll(value) {
      if (value) {
        // 如果全选被选中，设置所有复选框为选中
        this.list.forEach((item) => {
          this.$set(this.selectedItems, item.id, true)
        })
      } else {
        // 如果全选被取消，设置所有复选框为不选中
        this.list.forEach((item) => {
          this.$set(this.selectedItems, item.id, false)
        })
      }
    },

    // 计算全选状态
    updateSelectAllStatus() {
      const totalItems = this.list.length
      const selectedCount = Object.values(this.selectedItems).filter(
        (selected) => selected
      ).length
      this.selectAll = totalItems === selectedCount
    },
    // 催单
    reminder() {
      // 禁用催单按钮

      const selectedData = this.list
        .filter((item) => this.selectedItems[item.id])
        .map((item) => ({
          purchaseOrderType: item.purchaseOrderType,
          purchaseOrderId: item.purchaseOrderId,
          contractNumber: item.contractNumber,
          serialNumber: item.serialNumber,
        }))

      // 校验是否有选中项
      if (selectedData.length === 0) {
        // 如果没有选中任何项，提示用户
        this.$message.warning('请选择至少一个订单进行催单')
        return // 退出方法，不发送请求
      }

      this.isDisabledReminder = true

      api
        .reminder(selectedData)
        .then((response) => {
          // 添加成功后显示提示
          this.$toast.success('催单成功！')
          // 解除禁用
          this.isDisabledReminder = false
          // 催单数据清除
          this.selectAll = false
          this.selectedItems = {}
          // 刷新数据
          this.search()
        })
        .catch((error) => {
          // 添加失败时提示错误信息
          this.$toast.fail('催单失败，请重试！')
          // 解除禁用
          this.isDisabledReminder = false
        })
    },

    // 监听详情页的参数
    handleMessage(event) {
      if (event.data.action === 'onLoad') {
        const page = event.data.page // 获取页码
        this.refreshList(page)
      }
    },
    // 刷新某一页的数据
    refreshList(page) {
      // 根据选中的标签，选择不同的请求方法
      switch (this.activeIndex) {
        case 0:
          return this.fetchPageInTwoDays(page) // 异步调用
        case 1:
          return this.fetchPageInThreeDays(page)
        case 2:
          return this.fetchPageInExpiry(page)
        case 3:
          return this.fetchSelectPageByToday(page)
        case 4:
          return this.fetchSelectPageAll(page)
        default:
          return Promise.resolve()
      }

      // 删除从下标 i 开始的所有数据
      this.list.splice(page * this.pageSize)
    },

    tabSwitch() {
      //tab切换，重新初始化数据
      this.list = []
      this.pageNo = 1
      this.finished = false
      // 全选框
      this.selectAll = false
      this.selectedItems = {}

      if (this.activeIndex > 4) {
        this.showPopup = 4
      }

      //tabs切换时，如果之前的tab已经滚动到底部（list加载到底部），直接点击其他的tab，将再触发一次onload事件。
      //可能调用2次onLoad()方法，延迟执行，通过时间差解决问题
      setTimeout(() => {
        if (!this.finished) {
          this.onLoad()
        }
      }, 500)
    },
    // 查询标签列表
    selectLables() {
      api.selectAllLables().then((response) => {
        this.lablsList = response.data
      })
    },

    onLoad() {
      // 如果正在加载，直接返回
      if (this.loadingData) return

      this.loadingData = true // 标记为加载状态

      // 根据选中的标签，选择不同的请求方法
      switch (this.activeIndex) {
        case 0:
          return this.selectPageInTwoDays() // 异步调用
        case 1:
          return this.selectPageInThreeDays()
        case 2:
          return this.selectPageInExpiry()
        case 3:
          return this.selectPageByToday()
        case 4:
          return this.selectPageAll()
        default:
          return Promise.resolve()
      }
    },

    resetData() {
      this.searchObj = {}
      this.onRefresh()
    },

    // 向上刷新
    onRefresh() {
      if (this.loadingData) return

      // 清空列表数据
      this.finished = false
      this.list = []

      this.pageNo = 1
      // 重新加载数据
      this.onLoad().finally(() => {
        this.loadingData = false // 请求完成后清除加载状态
      })
    },

    // 搜索按钮
    search() {
      if (this.loadingData) return

      // 清空列表数据
      this.finished = false
      this.list = []

      this.pageNo = 1
      // 重新加载数据

      // 调用加载数据的函数
      this.onLoad().finally(() => {
        this.loadingData = false // 请求完成后清除加载状态
      })
    },

    // 查询俩天后到货的订单
    selectPageInTwoDays() {
      this.loadingData = true // 标记为加载状态
      return api
        .selectPageInTwoDays(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          // console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 请求失败后清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    // 俩天后到货订单刷新
    fetchPageInTwoDays(page) {
      api
        .selectPageInTwoDays(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false
        })
    },
    // 三天后到货订单
    selectPageInThreeDays() {
      this.loadingData = true // 标记为加载状态
      return api
        .selectPageInThreeDays(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          // console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            // item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 请求失败后清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    // 三天后到货订单刷新
    fetchPageInThreeDays(page) {
      api
        .selectPageInThreeDays(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false
        })
    },

    // 到期在途订单
    selectPageInExpiry() {
      this.loadingData = true // 标记为加载状态
      return api
        .selectPageInExpiry(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          // console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 请求失败后清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    // 在途订单刷新
    fetchPageInExpiry(page) {
      api
        .selectPageInExpiry(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false
        })
    },
    // 今日新增在途订单
    selectPageByToday() {
      this.loadingData = true // 标记为加载状态
      return api
        .selectPageByToday(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          // console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            // item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 请求失败后清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    // 今日新增订单刷新
    fetchSelectPageByToday(page) {
      api
        .selectPageByToday(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false
        })
    },
    // 全部在途订单
    selectPageAll() {
      this.loadingData = true // 标记为加载状态
      return api
        .selectPageAll(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          // console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            // item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 请求失败后清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    // 全部在途订单刷新
    fetchSelectPageAll(page) {
      api
        .selectPageAll(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false
        })
    },

    info(id, oldPage) {
      this.id = id
      this.oldPage = oldPage
      this.dialogVisible = true

      this.iframeVisible = false // 销毁 iframe
      this.$nextTick(() => {
        this.iframeVisible = true // 重新显示 iframe
      })
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.id = 0
      this.oldPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  background: #1d1e20;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
.container {
  padding-bottom: 50px;
  .list-wrap {
    margin-top: 4px;
    border-top: 1px solid #ebedf0;
  }
  .item-wrap {
    font-size: 12px;
    color: #a7a8a9;
    .item-header {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 4px;
      }
      h3 {
        flex: 1;
        font-size: 15px;
        color: #000;
        padding: 0;
        margin: 0;
      }
    }

    .item-block {
      padding: 4px 0;
      border: 1px solid #ddd; /* 添加边框，提升视觉分隔 */
      font-size: 14px;
      p {
        padding: 0;
        margin: 5px 0;
        color: #000;
        line-height: 20px;
      }
    }

    .item-status {
      .pass {
        color: #4cb971;
      }
      .refused {
        color: #eb8473;
      }
    }
  }
}
</style>
