import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/userInfo' // 引入 api 方法

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null, // 用于存储用户信息
    noPermission: false, // 用于存储是否有权限
  },
  mutations: {
    // 修改用户信息
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    // 修改权限状态
    setNoPermission(state, status) {
      state.noPermission = status
    },
  },
  actions: {
    // 异步获取用户信息
    fetchUserInfo({ commit }) {
      return api
        .userInfo() // 调用 api.userInfo() 获取数据
        .then((response) => {
          console.info('存储用户信息:', response.data)
          commit('setUserInfo', response.data) // 将获取到的用户信息保存到 Vuex 中
          return response.data // 返回数据，如果有需要，可以进行其他操作
        })
        .catch((error) => {
          console.error('获取用户信息失败:', error)
        })
    },
    // 设置无权限状态
    setNoPermission({ commit }, status) {
      commit('setNoPermission', status)
    },
  },
  getters: {
    // 获取用户信息
    getUserInfo: (state) => state.userInfo,
    // 获取权限状态
    getNoPermission: (state) => state.noPermission,
  },
  modules: {},
})
